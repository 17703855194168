import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { CollectionControls } from "@components/headings/CollectionControls";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Release } from "@models/release";
import { device } from "@styles/theme";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
	ArtistList,
	ArtistListContainer,
	Badges,
	Break,
	ButtonBackground,
	Controls,
	Description,
	ImageWrapper,
	Info,
	Meta,
	Name,
	Wrapper,
} from "./ReleaseDetailCard.style";

interface Props {
	release: Release;
	location?: string;
}

const ReleaseDetailCard: React.FC<Props> = ({ release, location }) => {
	const MAX_DESC_CHARACTERS = 265;
	const totalDescCharacters = release.desc ? release.desc.length : 0;
	const [isDescTextCut, setIsDescTextCut] = useState(totalDescCharacters > MAX_DESC_CHARACTERS);
	const [isArtistListExpanded, setIsArtistListExpanded] = useState(false);
	const [isArtistListOverflowing, setIsArtistListOverflowing] = useState(false);
	const artistList = useRef() as MutableRefObject<HTMLParagraphElement>;
	const artistListWidth = artistList.current?.scrollWidth;
	const metaContainer = useRef() as MutableRefObject<HTMLParagraphElement>;
	const metaContainerWidth = metaContainer.current?.clientWidth;

	const isXsm = useMediaQuery({ query: device.xsm });
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;
	const preOrder = `${t("PreOrder")}`;

	useEffect(() => {
		if (artistListWidth > metaContainerWidth) setIsArtistListOverflowing(true);
	}, [artistListWidth, metaContainerWidth]);

	return (
		<Wrapper>
			<ImageWrapper>
				{(release.exclusive || release.pre_order || release.is_hype) && (
					<Badges>
						{release.exclusive && <div className="exclusive">{exclusive.toUpperCase()}</div>}
						{release.pre_order && <div className="preorder">{preOrder.toUpperCase()}</div>}
						{release.is_hype && <div className="hype">HYPE</div>}
					</Badges>
				)}
				<Image
					src={release.image?.uri || ""}
					alt={release.name || ""}
					width="264"
					height="264"
					sizes="100vw"
				/>
			</ImageWrapper>
			<Meta className={isXsm ? "full" : undefined} ref={metaContainer}>
				<Name>{release.name}</Name>
				{release.artists && release.artists.length > 0 && (
					<ArtistListContainer>
						<ArtistList ref={artistList} isExpanded={isArtistListExpanded}>
							{renderArtistNames(release.artists, { location })}
						</ArtistList>
						{isArtistListOverflowing && (
							<ButtonBackground
								data-testid="release-artist-list-expand"
								onClick={() => {
									setIsArtistListExpanded(!isArtistListExpanded);
								}}
							>
								{isArtistListExpanded ?
										(
											<SpriteIcon id="triangle-up" width="24" height="24" />
										) :
										(
											<SpriteIcon id="triangle-down" width="24" height="24" />
										)}
							</ButtonBackground>
						)}
					</ArtistListContainer>
				)}
				<Info>
					<p>{t("ReleaseDate")}</p> <span>{release.new_release_date}</span>
				</Info>
				<Info>
					<p>{t("Label.Title")}</p>
					<span>
						<LabelLink label={release.label} location={location} withMarquee />
					</span>
				</Info>
				<Info>
					<p>{t("Catalog")}</p>
					<span>{release.catalog_number}</span>
				</Info>
				<Controls>
					<CollectionControls
						location={`Release - ${release.name}`}
						release={release}
						showPlay
						showAddToQueue
						showAddToPlaylist
						showAddToCart
						showSocial
					/>
				</Controls>
			</Meta>
			<Break />
			<Description>
				{release.desc && isDescTextCut ?
					`${release.desc.slice(0, MAX_DESC_CHARACTERS)}...` :
					release.desc}
				{totalDescCharacters > MAX_DESC_CHARACTERS && (
					<ButtonBackground
						data-testid="release-desc-expand"
						onClick={() => {
							setIsDescTextCut(!isDescTextCut);
						}}
					>
						{isDescTextCut ?
								(
									<SpriteIcon id="triangle-down" width="24" height="24" />
								) :
								(
									<SpriteIcon id="triangle-up" width="24" height="24" />
								)}
					</ButtonBackground>
				)}
			</Description>
		</Wrapper>
	);
};

export default ReleaseDetailCard;
