import styled, { css } from "styled-components";

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const ButtonBackground = styled.button`
  display: inline-block;
  float: right;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-left: 4px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const Description = styled.p`
  width: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

export const Controls = styled.div`
  padding: 4px 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -0.006em;

  padding-right: 4px;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  p {
    padding-right: 4px;
  }

  span {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  a {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ArtistListContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ArtistList = styled.div<{ isExpanded?: boolean }>`
  ${({ isExpanded }) => !isExpanded && css`
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  
  ${({ isExpanded }) => isExpanded && css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  `}

  a {
    font-family: ${(props) => props.theme.fonts.aeonik};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: ${(props) => props.theme.colors.neutrals.primary.white};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Name = styled.h1`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;

  .full {
    width: 100%;
  }
`;

export const Badges = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: black;
  width: 100px;

  div {
    padding: 0 8px;
  }

  div.hype {
    background: ${(props) => props.theme.colors.blue[500].accent};
  }

  div.exclusive {
    background: ${(props) => props.theme.colors.green[300].accent};
  }

  div.preorder {
    background: ${(props) => props.theme.colors.yellow[300].accent};
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 264px;
  margin-right: 1rem;

  @media ${(props) => props.theme.device.sm} {
    float: left;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
