import { NextPageWithLayout } from "@bptypes/layout";
import { ReleaseDetailCard } from "@components/cards/ReleaseDetailCard";
import { Box } from "@components/core/Box";
import { TracksList } from "@components/lists/TracksList";
import { Pager } from "@components/paging";
import { PER_PAGE_OPTIONS } from "@components/paging/Pager/Pager";
import { PagerWrapper } from "@components/paging/Pager/Pager.style";
import { TerritoryRestricted } from "@components/restricted";
import { GridSlider } from "@components/sliders/GridSlider";
import { TracksTable } from "@components/tables/TracksTable";
import MainLayout from "@layouts/MainLayout";
import { DEFAULT_LOCALE } from "@lib/constants";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import {
	getReleaseQuery,
	getReleaseRecommendationsQuery,
	getReleasesQuery,
} from "@lib/network/releases";
import { getReleaseTracksQuery, getTracksQuery } from "@lib/network/tracks";
import { getSafeDefaultPageParam } from "@lib/utils";
import { pushProductDetailViewEvent } from "@lib/utils/dataLayer";
import { getLocationData } from "@lib/utils/getLocationData";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import { DehydratedState, QueryClient, dehydrate, useQuery } from "@tanstack/react-query";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";

interface Props {
	release?: Release;
	anonSession: AnonSession;
}

const ReleasePage: NextPageWithLayout<Props> = ({ release, anonSession }) => {
	const { t } = useTranslation("translation");
	const isDesktop = useMediaQuery({ query: device.xl });
	const router = useRouter();
	const { getAccessToken, getIsSessionValid, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const page = router.query.page;

	const [queryParams, setQueryParams] = useState({
		page: getSafeDefaultPageParam(page),
		per_page: PER_PAGE_OPTIONS[2],
	});

	useEffect(() => {
		setQueryParams({
			...queryParams,
			page: getSafeDefaultPageParam(page),
		});
	}, [page]);

	const { data: tracks } = useQuery({
		...getReleaseTracksQuery({
			releaseId: release?.id || 0,
			params: queryParams,
			accessToken,
		}),
		enabled: isSessionValid,

	},

	);

	// people also bought
	const { data: recommendedReleases } = useQuery({
		...getReleaseRecommendationsQuery({ id: release?.id || 0, accessToken }),
		enabled: isLoggedInSessionValid,
	},

	);

	const { data: popularLabelReleases } = useQuery({
		...getReleasesQuery({
			params: {
				label_id: release?.label?.id.toString() || "0",
				order_by: "-publish_date",
			},
			accessToken,
		}),
		enabled: isSessionValid,
	},

	);

	const location = release && `Release Page - ${release.name}`;

	useEffect(() => {
		if (release && tracks) {
			pushProductDetailViewEvent({
				ecommerce: {
					currencyCode: release?.price?.code || "",
					detail: {
						products: [
							{
								id: release.id.toString(),
								name: release.name,
								list: location || "",
								artists: release?.artists?.map((artist) => artist.name).join(",") || null,
								remixers: null,
								genres: release.genres?.map((genre) => genre.name).join(",") || null,
								subGenres: null,
								preOrder: release.pre_order || null,
								type: "product",
								category: "Release",
								creative: "Release Detail",
								variant: "release",
								price: release.price?.value,
								imageUrl: release.image?.uri || null,
								tracks: tracks.results.map((track: Track) => {
									return {
										id: track.id.toString(),
										name: track.name,
										list: location || "",
										artists: track?.artists.map((artist) => artist.name).join(", "),
										remixers: track?.remixers
											.map((remixer) => remixer.name)
											.join(", "),
										genres: track?.genre?.name,
										subGenres: track?.sub_genre?.name || null,
										preOrder: track.pre_order,
										type: "product",
										category: "Tracks",
										creative: "Release Detail",
										variant: "track",
										price: track?.price?.value,
										imageUrl: track?.image?.uri,
									};
								}),
							},
						],
					},
				},
			});
		}
	}, [release, tracks]);

	if (release && (release as any).detail === "Territory Restricted.") {
		return <TerritoryRestricted />;
	}

	return (
		<>
			{release && <ReleaseDetailCard release={release} location={location} />}
			{isDesktop ?
					(
						<TracksTable
							isLoading={false}
							tracks={tracks ? tracks.results : []}
							location={location}
							isPaginated
							perPage={queryParams.per_page}
							page={queryParams.page}
						/>
					) :
					(
						<TracksList
							location={location}
							isLoading={false}
							tracks={tracks ? tracks.results : []}
							showArtwork
							showNumbers
							isPaginated
							perPage={queryParams.per_page}
							page={queryParams.page}
						/>
					)}
			<PagerWrapper className="right">
				<Pager
					defaultPerPage={queryParams.per_page}
					totalResults={tracks?.count}
					hideResultsPerPage={true}
				/>
			</PagerWrapper>
			<Box p="8" />
			{recommendedReleases &&
				recommendedReleases.results &&
				recommendedReleases.results.length > 0 && (
				<GridSlider
					type="releases"
					title={t("PeopleAlsoBought")}
					data={recommendedReleases.results}
					rows={1}
					wideGrid
				/>
			)}

			<Box p="8" />
			{popularLabelReleases &&
				popularLabelReleases.results &&
				popularLabelReleases.results.length > 0 && (
				<GridSlider
					type="releases"
					title={t("MoreFromThisLabel")}
					data={popularLabelReleases.results.filter(
						(r: Release) => r.id != release?.id,
					)}
					rows={1}
					wideGrid
				/>
			)}
		</>
	);
};

export default ReleasePage;

ReleasePage.getLayout = (page: ReactElement<Props>) => {
	const restricted =
		(page.props.release as any).detail === "Territory Restricted.";
	const releaseImage: string = (page.props.release?.image.uri || "");
	const artists = page.props.release?.artists?.map((a) => a.name).join(", ");
	return (
		<MainLayout
			title={
				restricted ?
					"Oh No! Territory Restricted. " :
					`${artists} - ${page.props.release?.name} [${page.props.release?.label.name}] | Music & Downloads on Beatport`
			}
			noSuffix
			metaDescription={`"${artists} - ${page.props.release?.name} [${page.props.release?.label.name}]" | Find the latest releases here | #1 source for DJ Sets and more`}
			hasDesktopSidebar={true}
			socialImage={releaseImage}
		>
			{page}
		</MainLayout>
	);
};

type ServerSideProps = {
	dehydratedState: DehydratedState;
	anonSession: AnonSession;
	release: Release;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
	ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<ServerSideProps>> => {
	const queryClient = new QueryClient();
	const location = getLocationData(ctx.req);

	const { id, page = 1 } = ctx.query;
	const releaseId = id ? parseInt(id as string, 10) : 0;
	const pageNumber = Array.isArray(page) ? parseInt(page[0], 10) : parseInt(page as string, 10);
	let release: Release | null = null;

	if (releaseId > 0) {
		const data = await Promise.all([
			queryClient
				.fetchQuery<Release>(getReleaseQuery({ id: releaseId, location }))
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getTracksQuery({
						params: {
							release_id: releaseId,
							per_page: PER_PAGE_OPTIONS[2],
							page: pageNumber,
						},
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getReleaseRecommendationsQuery({
						id: releaseId || 0,
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
		]);
		if (data.length > 0) {
			release = data[0] as Release;
		}
	}

	const anonSession = await exportAnonSession();

	if (!release) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			anonSession,
			release: release,
			...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ["translation"])),
		},
	};
};
