import Button from "@components/Button";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { Wrapper } from "./TerritoryRestricted.style";

export const TerritoryRestricted: React.FC = () => {
	const router = useRouter();
	const { t } = useTranslation("translation");
	return (
		<Wrapper>
			<Image
				src="/images/territory-restricted.png"
				width={120}
				height={111}
				alt="Territory Restriction icon"
			/>
			<h2 className="title">{t("Error.TerritoryRestriction")}</h2>
			<p>
				{t("Error.SorryNotAvailableRegion")}
			</p>
			<Button
				type="primary"
				onClick={() => {
					router.push("/");
				}}
			>
				{t("Error.ReturnToHomepage")}
			</Button>
		</Wrapper>
	);
};

export default TerritoryRestricted;
